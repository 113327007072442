























import { Investment, PaymentStatus } from '@/interfaces/investment'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { groupBy } from 'underscore'
const ChartJS = (window as any).Chart

@Component({})
export default class TopInvestors extends Vue {
  @Prop({ required: false, default: 'Maiores investidores' }) title!: string
  @Prop({ required: false, default: '' }) urlOferta!: string

  myChart: any = null

  investments: Investment[] = []

  labels: any[] = []
  data: any[] = []

  loading = false

  mounted() {
    this.initializeTopInvestorsChart()
    this.fetchInvestments()
  }

  @Watch('investments')
  onInvestmentsChange() {
    const { labels, data } = this.buildDataset()

    this.renderChart(this.myChart, labels, [{
      barPercentage: 1,
      categoryPercentage: 0.5,
      data: data,
      backgroundColor: '#B4DC7F',
      borderColor: '#317B22',
      borderWidth: 1
    }])
  }

  initializeTopInvestorsChart() {
    const ctx = (this.$refs.investorsCanvas as any).getContext('2d')

    this.myChart = new ChartJS(ctx, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [{
          data: this.data
        }]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$options.filters?.currency(tooltipItem.yLabel)
            }
          }
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                return this.$options.filters?.currency(value)
              }
            }
          }]
          /* xAxes: [{
            ticks: {
              callback: (value) => {
                return this.$options.filters?.currency(value);
              }
            }
          }] */
        }
      }
    })
  }

  fetchInvestments() {
    this.loading = true

    this.$store
      .dispatch('investment/search', {
        pagination: false,
        filters: {
          url_oferta: {
            type: '$eq',
            value: this.urlOferta
          }
        }
      })
      .then((investments) => {
        this.investments = investments
        this.loading = false
      })
  }

  buildDataset() {
    const paidInvestments = this.paidInvestments

    const groupByInvestor = groupBy(paidInvestments, (i) => i.investidor.email)

    const investors = Object.keys(groupByInvestor).map((email: string) => {
      const sumOfInvestments = groupByInvestor[email].reduce((acc, cur) => {
        return acc + parseInt(cur.valor)
      }, 0)

      return {
        email: email,
        valor: sumOfInvestments
      }
    })

    const ordered = investors.sort((a, b) => b.valor - a.valor).slice(0, 10)

    this.labels = ordered.map(investor => investor.email)
    this.data = ordered.map(investor => investor.valor)

    return {
      labels: this.labels,
      data: this.data
    }
  }

  renderChart(chart, labels, datasets) {
    chart.data.labels = labels
    chart.data.datasets = datasets

    chart.update()
  }

  get paidInvestments() {
    return this.investments.filter((i: Investment) => {
      return PaymentStatus[i.pagamento.status] === PaymentStatus.PAGO
    })
  }

  get investmentValueAverage() {
    if (!this.paidInvestments.length) return 0
    return this.paidInvestments.reduce((acc, cur) => acc + cur.valor, 0) / this.paidInvestments.length
  }

  get topPayment() {
    if (!this.paidInvestments.length) return 0
    const ordered = this.paidInvestments.sort((a, b) => b.valor - a.valor)
    return ordered[0].valor
  }
}

