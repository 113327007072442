import { InvestmentQueue } from '@/interfaces/investment'
import axios, { AxiosInstance } from 'axios'

interface OpenInvestmentQueue {
  url_oferta: string;
  valor_disponivel: number;
}

export default class InvestmentQueueService {
  private ENDPOINT = 'https://api.startmeup.com.br/Gateway/v1'
  private INVESTMENT_QUEUE_ENDPOINT = 'https://lista-espera-dot-startmeup-218419.rj.r.appspot.com'

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  allQueues(): Promise<InvestmentQueue[]> {
    return this.client
      .get(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/lista`)
      .then(response => response.data)
  }

  openInvestmentQueue(form: OpenInvestmentQueue) {
    return this.client
      .post(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/nova`, { ...form })
      .then(response => response.data)
  }

  editInvestmentQueue(form: { url_oferta: string; valor_disponivel: number }) {
    return this.client
      .put(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/atualiza`, { ...form })
      .then(response => response.data)
  }

  pauseQueue(url_oferta: string) {
    return this.client
      .post(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/inativa/${url_oferta}`)
      .then((response) => response.data)
  }

  resumeQueue(url_oferta: string) {
    return this.client
      .post(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/ativa/${url_oferta}`)
      .then((response) => response.data)
  }

  closeQueue(url_oferta: string) {
    return this.client
      .post(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/fecha/${url_oferta}`)
      .then((response) => response.data)
  }

  removeFromQueue(url_oferta: string, email: string) {
    return this.client
      .put(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/investidor/cancela/${url_oferta}`, {}, { params: { email } })
      .then((response) => response.data)
  }

  cancelPaymentLink(urlOferta, id) {
    return this.client
      .put(`${this.INVESTMENT_QUEUE_ENDPOINT}//lista-de-espera/investidor/cancela-pagamento-gerado/${urlOferta}`, {}, { params: { id } })
      .then((response) => response.data)
  }

  // lista-de-espera/investidor/cancela-pagamento-gerado/{urlOferta} queryparam: id

  findQueueByOffer(url_oferta: string) {
    return this.client
      .get(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/busca`, { params: { oferta: url_oferta } })
      .then((response) => response.data)
  }

  createPaymentLink(urlOferta, params) {
    return this.client
      .put(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/investidor/cria-pagamento/${urlOferta}`, { ...params })
      .then((response) => response.data)
  }

  // Remove null, undefined and empty strings from object.
  private sanitizeQueryParams(object: Record<string, any>) {
    return Object
      .keys(object)
      .filter(key => typeof object[key] === 'boolean' || object[key] === 0 || !!object[key])
      .reduce((acc, cur) => { acc[cur] = object[cur]; return acc }, {})
  }
}
