/*
 * This interface represents a Offer (by entrepreneur side)
 */
export interface Offer {
  id?: string;
  nome_oferta: string;
  url_oferta: string;
  site_oferta: string;
  url_logo: string;
  url_cover: string;
  descricao?: string;
  tipo_contrato?: ContractType;
  tipo_oferta?: OfferType;
  historia: string;
  diferencial: string;
  opiniao: string;
  cnpj: string;
  pitch: string;
  url_video: string;
  cidade: string;
  estado: string;
  fim_captacao: string;
  inicio_captacao: string;
  fundacao: string;
  numero_investidores: number;
  meta_esperada: string;
  cota_minima: string;
  isValida: boolean;
  status: string;
  simulador?: Simulator;
  valor_investido: string;
  valor_investimentos_pagos: string;
  link_grupo: string;
  plano_de_negocio: string;
  investidores?: any[];
  tamanho_time?: number;
  fundadores: Founder[];
  setores_atuacao: Sectors[];
  raio_x: RaioXItem[];
  RI?: RI;
  documentos: Document[];
  kpis: KPI[];
  links: Link[];
  audios: any[];
  investimento_colider: number;
  tags: {nome: string; cor: string; id: string}[];
  valor_unitario_da_cota: number;
  nome_emissor: string;
  empresa_responsavel: string;
  receita_bruta: number;
  lucro_bruto: number;
  margem_bruta: number;
  ebitda: number;
  lucro_liquido: number;
  margem_liquida: number;

  modalidade: string;
  rentabilidade_valor: number;
  rentabilidade_periodo: string;
  rentabilidade_prazo_meses: number;
  porcentagem_tipo: string;
  porcentagem: number;
  porcentagem_max: number;
  porcentagem_min: number;
  pagamento_periodo: string;
  pagamento_periodo_descricao: string;
}

export interface RI {
  id?: string;
  link_grupo?: string;
  faturamento?: number;
  funcionarios?: number;
  clientes?: number;
  acoes_civis?: number;
  evolucao_faturamento?: number;
  link_documento?: string;
  depoimento?: {
    autor: string;
    texto: string;
  };
}

export enum OfferStatus {
  AGUARDANDO = 'Aguardando',
  PRIVADO = 'Privado',
  EM_CAPTACAO = 'Em captação',
  EM_DEVOLUCAO = 'Em Devolução',
  LIQUIDANDO = 'Liquidando',
  SUSPENSA = 'Suspensa',
  CONCLUIDA = 'Concluida',
  CONCLUIDA_PRIVADO = 'Concluida Privado'
}

export interface RaioXItem {
  id: string;
  tipo_raiox: string;
  estrelas: number;
}

export interface Simulator {
  id?: string;
  tipo_simulador: string;
  valuation_base: string;
  valuation_otimista: string;
  link_simulador_investimento: string;
  valuation_minimo: string;
  correcao_monetaria: number;
  participacao_minima: number;
  fluxo_de_caixa: string[];
}

export interface Founder {
  id?: string;
  nome: string;
  cargo: string;
  linkedin: string;
  biografia: string;
  url_foto?: string;
}

export enum OfferType {
  DESCONTO_VALUATION_FUTURO = 'de desconto no valuation futuro da empresa',
  PARTICIPACAO = 'de participação da empresa',
  MUTUO_CONVERSIVEL = 'mútuo conversível',
  FOLLOWON_ANTI_DILUICAO = 'Follow On anti diluição',
  CERTIFICADO_DE_RECEBIVEL = 'Certificado de Recebível',
  NOTA_COMERCIAL = 'Nota Comercial'
}

export enum ContractType {
  GARANTIA_REAL = 'Investimento coletivo de dívida com garantia real',
  DIVIDA = 'Investimento coletivo de dívida',
  PARTICIPACAO_TRES_ANOS = 'Investimento coletivo com opção de conversão em participação no prazo de 3 anos',
  PARTICIPACAO_CINCO_ANOS = 'Investimento coletivo com opção de conversão em participação no prazo de 5 anos',
  MUTUO_CONVERSIVEL = 'mútuo conversível',
  FOLLOWON_EQUITY = 'Follow On e Conversão em Equity',
  MEMORANDO_DE_EMISSAO_DE_ACOES = 'Memorando de Emissão de Ações',
  SINDICATO_DEBENTURE_CONVERSIVEL_SAFE = 'Sindicato de Debênture Conversível SAFE',
  SINDICATO_DEBENTURE_CONVERSIVEL = 'Sindicato de Debênture Conversível',
  NOTA_COMERCIAL = 'Nota Comercial',
  CERTIFICADO_DE_RECEBIVEL = 'Certificado de Recebível'
}

export enum Sectors {
  FINTECH = 'Fintech',
  VAREJO_DIGITAL = 'Varejo Digital',
  MARKETING_DIGITAL = 'Marketing Digital',
  DIVIDA_CONVERSIVEL = 'Dívida Conversível',
  MOBILIDADE = 'Mobilidade',
  BEBIDAS = 'Bebidas',
  ALIMENTACAO = 'Alimentação',
  SAUDE = 'Saúde',
  IMOBILIARIO = 'Imobilário',
  FLORESTAL = 'Florestal',
  SAAS = 'SaaS',
  INTELIGENCIA_ARTIFICIAL = 'Inteligência Artificial',
  EDUCACAO = 'Educação',
  PLATAFORMAS_DIGITAIS = 'Plataformas Digitais',
  SERVICOS_FINANCEIROS = 'Serviços Financeiros',
  FOODTECH = 'Foodtech',
  CLEANTECH = 'Clean Tech',
  E_COMMERCE = 'E-Commerce',
  INDUSTRIA = 'Indústria',
  SERVICOS_DIVERSOS = 'Serviços Diversos',
  TECNOLOGIA = 'Tecnologia',
  SUPPLY_CHAIN = 'Supply Chain',
  COMERCIO = 'Comércio',
  AGRONEGOCIO = 'Agronegócio',
  MARKETPLACE = 'Marketplace',
  BEAUTYTECH = 'Beautytech',
  IMPACTO_AMBIENTAL = 'Impacto Ambiental'
}

export const OfferToCSV = {
  Nome: 'nome_oferta',
  'URL da Oferta': 'url_oferta',
  'Início da Captação': 'inicio_captacao',
  'Final da Captação': 'fim_captacao',
  Meta: 'meta_esperada',
  'Porcentagem oferecida': 'porcentagem',
  'Cota mínima': 'cota_minima',
  'Valor investido': 'valor_investido',
  'Valor investido (faturas pagas)': 'valor_investimentos_pagos',
  Status: 'status'
}

export enum SimulatorType {
  NAO_POSSUI = 'Não Possui',
  EXCEL = 'Excel',
  PLATAFORMA = 'Mútuo Conversível',
  SAFE = 'Mútuo Conversível (SAFE)',
  DEBENTURE_CONVERSIVEL = 'Debênture Conversível',
  DEBENTURE_CONVERSIVEL_SAFE = 'Debênture Conversível (SAFE)'
}

export interface Document {
  id: string;
  nome: string;
  url: string;
  tipo: string;
}

export interface KPIParam {
  nome: string;
  valor: number;
}

export interface KPI {
  id: string;
  nome: string;
  descricao: string;
  unidade: 'CRU' | 'PORCENTAGEM' | 'MONETARIO';
  parametros: KPIParam[];
  tipo_grafico: null | 'BAR' | 'PIZZA';
}

export interface Link {
  id: string;
  nome: string;
  url: string;
  codigo_embed: string;
  embed: boolean;
  tipo: null | 'WEBINAR' | 'PODCAST' | 'OUTRO';
}

export interface NewDocument {
  arquivo: File;
  documento: {
    id?: string;
    tipo: string;
    nome: string;
    nome_arquivo: string;
    bucket?: string;
    pasta?: string;
  };
}

export interface NewAudio {
  arquivo: File;
  audio: {
    url_audio?: string;
    id?: string;
    tipo: string;
    nome: string;
    nome_arquivo: string;
    descricao: string;
    bucket?: string;
    pasta?: string;
  };
}

export interface NewFounder {
  arquivo: File;
  fundador: {
    id?: string;
    nome: string;
    cargo: string;
    linkedin: string;
    biografia: string;
    tipo: string;
    bucket?: string;
    pasta?: string;
    nome_arquivo: string;
  };
}
