import moment from 'moment'

export enum PaymentStatus {
  PENDENTE = 'Pendente',
  PAGO = 'Pago',
  CANCELADO = 'Cancelado',
  EXPIRADO = 'Expirado',
  REEMBOLSADO = 'Reembolsado',
  REEMITIDO='Reemitido',
  VENCIDA='Vencida'
}

export interface Invoice {
  id: string;
  invoice_id: string;
  url: string;
  status: string;
  taxes: number;
  qrcode: string;
  qrcode_text: string;
  payment_method: string;
  created_at: string;
  updated_at: string;
  paid_at: string;
}
export interface Order {
  id: string;
  invoice_id: string;
  price_cents: number;
  url_oferta: string;
  document_number: string;
  email: string;
  full_name: string;
  ip: string;
  user_agent: string;
  created_at: string;
  updated_at: string;
  expires_at: string;
  invoice: Invoice;
}

export interface Payment {
  criado: string;
  id_fatura_iugu: string;
  url_fatura_iugu: string;
  status: PaymentStatus;
  data_pagamento: string;
}

export interface Investment {
  id: string;
  investidor: { [key: string]: any };
  nome_oferta: string;
  url_oferta: string;
  pagamento: Payment;
  contrato?: boolean;
  valor: number;
}

export interface InvestorDeclaration {
  tipo: string;
  ip: string;
  user_agent: string;
  assinado: boolean;
}

export interface ContractSignature {
  id_investimento: string;
  ip: string;
  user_agent: string;
}

export const InvestmentToCSV = {
  Email: 'investidor.email',
  Nome: 'investidor.nome',
  Telefone: 'investidor.endereco.telefone',
  Valor: 'valor',
  Empresa: 'nome_oferta',
  'Data Investimento': {
    field: 'criado',
    callback: (value) => {
      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    }
  },
  'Taxas (em centavos)': 'ordem.invoice.taxes',
  CPF: 'investidor.cpf',
  Endereço: 'investidor.endereco.endereco',
  Numero: 'investidor.endereco.numero',
  Complemento: 'investidor.endereco.complemento',
  Bairro: 'investidor.endereco.bairro',
  Cidade: 'investidor.endereco.cidade',
  Estado: 'investidor.endereco.estado',
  CEP: 'investidor.endereco.cep',
  'Id da Ordem': 'ordem.id',
  'ID da Invoice Interna': 'ordem.invoice.id'
}

export const InvestmentQueueToCSV = {
  Email: 'email',
  Posição: 'posicao',
  Valor: 'valor',
  'Data do pedido': 'data_criacao'
}

export interface InvestmentQueue {
  ativa: boolean;
  id: string;
  url_oferta: string;
  valor_disponivel: number;
  data_abertura: string;
  data_fechamento: string;
  investidores: any[];
  investidoresPagamentoGerado: any[];
  investidoresCancelados: any[];
}
