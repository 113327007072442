













































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import currency from 'currency.js'

@Component({
  name: 'offer-ruler'
})
export default class OfferRuler extends Vue {
  @Prop({}) status!: string[]
  @Prop({}) total!: number
  @Prop({}) reserved!: number
  @Prop({}) paid!: number

  /**
   * Computed propertie to show the sum of paid width.
   */
  get paidWidth() {
    const paid = currency(this.paid, { separator: '.', decimal: ',' }).value
    const total = currency(this.total, { separator: '.', decimal: ',' }).value
    return {
      width: (paid / total) * 100 + '%'
    }
  }

  /**
   * Computed propertie to show the sum of reserved width.
   */
  get reservedWidth() {
    const reserved = currency(this.reserved, { separator: '.', decimal: ',' })
      .value
    const paid = currency(this.paid, { separator: '.', decimal: ',' }).value
    const total = currency(this.total, { separator: '.', decimal: ',' }).value
    return {
      width: ((reserved - paid) / total) * 100 + '%'
    }
  }

  /**
   * Computed propertie to show the sum of acquired quotas.
   */
  get acquiredQuotas() {
    const paid = currency(this.paid, { separator: '.', decimal: ',' }).value
    const total = currency(this.total, { separator: '.', decimal: ',' }).value
    return Number((paid / total) * 100).toFixed(2)
  }

  /**
   * Computed propertie to show the sum of active reservations.
   */
  get activeReservations() {
    const reserved = currency(this.reserved, { separator: '.', decimal: ',' })
      .value
    const total = currency(this.total, { separator: '.', decimal: ',' }).value
    return Number((reserved / total) * 100).toFixed(2)
  }
}
