import { UserAddress } from './user'

export enum PaymentLinkType {
  COMERCIAL = 'Comercial',
  LISTA_ESPERA = 'Lista de Espera',
}

export interface PaymentLinkForm {
  url_oferta: string;
  valor: number;
  tipo: PaymentLinkType;
  vencimento?: string;
  investidor: {
    nome: string;
    email: string;
    cpf_cnpj: string;
    endereco: UserAddress;
  };
}

export interface PaymentLink {
  id: string;
  tipo: PaymentLinkType;
  valor: number;
  ativo: boolean;
  criado: string;
  vencimento: string;
  investidor: {
    nome: string;
    email: string;
    cpf_cnpj: string;
    endereco: UserAddress;
  };
}

export const PaymentLinkToCSV = {
  'Nome Completo': 'investidor.nome',
  Email: 'investidor.email',
  'CPF / CNPJ': 'investidor.cnpf_cnpj',
  Valor: 'valor',
  Oferta: 'url_oferta',
  Tipo: 'tipo',
  Ativo: 'ativo',
  'Data de Criação': 'criado',
  'Data de Vencimento': 'vencimento',
  Telefone: 'investidor.endereco.telefone',
  Endereco: 'investidor.endereco.rua',
  Bairro: 'investidor.endereco.bairro',
  Complemento: 'investidor.endereco.complemento',
  Cidade: 'investidor.endereco.cidade',
  Estado: 'investidor.endereco.estado',
  CEP: 'investidor.endereco.cep',
  Numero: 'investidor.endereco.numero'
}
