import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { PaymentLinkForm, PaymentLink } from '@/interfaces/payment-link'
import { Pagination } from '@/interfaces/pagination'

export default class PaymentLinkService {
  public ENDPOINT = 'https://link-pagamento-dot-startmeup-218419.rj.r.appspot.com'

  private client: AxiosInstance

  constructor(client = axios) {
    this.client = client
  }

  all(options: Record<string, any> = {}): Promise<{ items: PaymentLink[]; pagination: Pagination }> {
    return this.client
      .get(`${this.ENDPOINT}/lista`, { params: options })
      .then((response: AxiosResponse) => response.data)
      .then((data) => {
        return {
          items: data.items,
          pagination: {
            itemsPorPagina: data.itemsPorPagina,
            numeroDePaginas: data.numeroDePaginas,
            pagina: data.pagina,
            proximaPagina: data.proximaPagina,
            temPaginaAnterior: data.temPaginaAnterior,
            temProximaPagina: data.temProximaPagina
          }
        }
      })
  }

  findPaymentLink(id: string) {
    return this.client
      .get(`${this.ENDPOINT}/${id}`)
      .then((response) => response.data)
  }

  new(form: PaymentLinkForm) {
    return this.client
      .post(`${this.ENDPOINT}`, { ...form })
      .then((response) => response.data)
  }

  delete(paymentLinkId: string) {
    return this.client
      .delete(`${this.ENDPOINT}/${paymentLinkId}`)
      .then((r) => r.data)
  }
}
