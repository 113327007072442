











































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import { Offer, OfferStatus } from '@/interfaces/offer'
import { InvestmentQueue } from '@/interfaces/investment'
import { chunk, groupBy } from 'underscore'
import { mask } from 'vue-the-mask'
import { CurrencyInput } from 'vue-currency-input'
import InvestmentService from '@/services/Investment.service'

interface FilterOptions {
  query: string;
}

interface RawForm {
  offer: Offer | null;
  valor_disponivel: number;
}

@Component({
  components: {
    CurrencyInput
  },
  directives: {
    mask
  }
})
export default class Users extends Vue {
  private offerService = new OfferService();
  private investmentService = new InvestmentService();

  $moment;
  chunk = chunk
  offers: Offer[] = [];
  showAllOffers = false;

  OfferStatus = OfferStatus

  form: RawForm = {
    offer: null,
    valor_disponivel: 0
  }

  formState = {
    loading: false
  }

  state = {
    loading: false
  }

  queues: InvestmentQueue[] = []

  filter: FilterOptions = {
    query: ''
  }

  async mounted() {
    this.state.loading = true
    this.queues = await this.loadQueues()
    this.offers = await this.loadOffers()
    this.state.loading = false
  }

  findOffer(url_oferta) {
    return this.offers.find(o => o.url_oferta === url_oferta)
  }

  loadQueues() {
    return this.investmentService.allQueues()
  }

  get activeQueues() {
    return this.queues
      .filter(q => q.ativa)
  }

  get pausedQueues() {
    return this.queues
      .filter(q => !q.ativa && !q.data_fechamento)
  }

  get closedQueues() {
    return this.queues
      .filter(q => !q.ativa && q.data_fechamento)
  }

  async loadOffers() {
    return this.offerService.all()
  }

  get liquidOffers() {
    return this.offers.filter((o: Offer) => OfferStatus[o.status] === OfferStatus.LIQUIDANDO)
  }

  get groupedByStatus() {
    return groupBy(this.offers, 'status')
  }

  get orderedOffers() {
    return this.offers.sort((a, b) => {
      return this.$moment(b.inicio_captacao, 'DD/MM/YYYY HH:mm:ss').diff(this.$moment(a.inicio_captacao, 'DD/MM/YYYY HH:mm:ss'))
    })
  }

  get filteredOffers() {
    if (!this.filter.query) return this.orderedOffers

    return this.orderedOffers.filter((offer) => {
      const q = this.filter.query.toLowerCase()
      const offerName = offer.nome_oferta.toLowerCase()
      const offerSlug = offer.url_oferta.toLowerCase()

      return offerSlug.includes(q) || offerName.includes(q)
    })
  }

  emptyForm() {
    return {
      offer: null,
      valor_disponivel: 0
    } as RawForm
  }

  onFormSubmit() {
    this.formState.loading = true

    const form = {
      url_oferta: this.form.offer?.url_oferta as string,
      valor_disponivel: this.form.valor_disponivel as number
    }

    if (!form.url_oferta || !form.valor_disponivel) {
      alert('Revise os campos obrigatórios.')
      return
    }

    this.investmentService.openInvestmentQueue(form)
      .then((queue: InvestmentQueue) => {
        this.queues.push(queue)
        this.form = this.emptyForm()
      })
      .catch(() => {
        alert('Erro ao abrir lista de espera. Contate o suporte.')
        this.form = this.emptyForm()
      })
      .then(() => { this.formState.loading = false })
  }
}

