







































































































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import { Offer, OfferStatus } from '@/interfaces/offer'
import { chunk, groupBy } from 'underscore'

interface FilterOptions {
  query: string;
}

@Component({})
export default class Users extends Vue {
  private offerService = new OfferService();

  $moment;
  chunk = chunk
  offers: Offer[] = [];

  OfferStatus = OfferStatus

  filter: FilterOptions = {
    query: ''
  }

  mounted() {
    this.loadOffers()
  }

  async loadOffers() {
    this.offers = await this.offerService.all()
  }

  get groupedByStatus() {
    return groupBy(this.offers, 'status')
  }

  get orderedOffers() {
    return this.offers.sort((a, b) => {
      return this.$moment(b.inicio_captacao, 'DD/MM/YYYY HH:mm:ss').diff(this.$moment(a.inicio_captacao, 'DD/MM/YYYY HH:mm:ss'))
    })
  }

  get filteredOffers() {
    if (!this.filter.query) return this.orderedOffers

    return this.orderedOffers.filter((offer) => {
      const q = this.filter.query.toLowerCase()
      const offerName = offer.nome_oferta.toLowerCase()
      const offerSlug = offer.url_oferta.toLowerCase()

      return offerSlug.includes(q) || offerName.includes(q)
    })
  }

  get chunks() {
    return chunk(this.filteredOffers, 4)
  }
}

