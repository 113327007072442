
















































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class Pagination extends Vue {
  @Prop({ required: true }) options;

  toPage(n: number) {
    this.$emit('toPage', n)
  }

  get range() {
    if (this.options.numeroDePaginas <= 10) {
      return Array.from(Array(this.options.numeroDePaginas).keys()).map(n => n + 1)
    }

    if (this.options.numeroDePaginas > 10 && this.options.pagina <= 5) {
      return Array.from(Array(this.options.numeroDePaginas).keys()).slice(1, 10)
    }

    return Array.from(Array(this.options.numeroDePaginas).keys()).slice(this.options.pagina - 4, this.options.pagina + 4)
  }
}
