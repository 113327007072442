

























































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Pagination from '@/components/Pagination.vue'
import JsonExcel from 'vue-json-excel'
import { Pagination as IPagination } from '@/interfaces/pagination'
import OfferRuler from '@/components/OfferRuler.vue'
import { User } from '@/interfaces/user'
import { InvestmentQueue, InvestmentQueueToCSV } from '@/interfaces/investment'
import { mask } from 'vue-the-mask'
import InvestmentService from '@/services/Investment.service'
import OfferService from '@/services/Offer.service'
import { Offer } from '@/interfaces/offer'
import UserService from '@/services/User.service'
import PaymentLinkService from '@/services/PaymentLink.service'
import InvestmentQueueService from '@/services/InvestmentQueue.service'
import { PaymentLinkForm, PaymentLinkType } from '@/interfaces/payment-link'
import { CurrencyInput } from 'vue-currency-input'

interface FilterOptions {
  email: string | null;
}

@Component({
  components: {
    Pagination,
    JsonExcel,
    OfferRuler,
    CurrencyInput
  },
  directives: {
    mask
  }
})
export default class Users extends Vue {
  private offerService = new OfferService();
  private investmentService = new InvestmentService();
  private userService = new UserService();
  private paymentLinkService = new PaymentLinkService();
  private investmentQueueService = new InvestmentQueueService();

  // Fix lint error.
  InvestmentQueueToCSV = InvestmentQueueToCSV
  $moment
  PaymentLinkType = PaymentLinkType

  offer: Offer = {} as Offer

  queue: InvestmentQueue = {} as InvestmentQueue

  users: User[] = []

  selected: any = null

  currencyOptions = {
    currency: 'BRL', locale: 'pt-BR'
  }

  options = {
    itemsPorPagina: 20,
    pagina: 0
  }

  form = {
    url_oferta: '',
    valor_disponivel: 0
  }

  filter: any = {
    queue: {
      email: null
    },
    canceled: {
      email: null
    },
    payment: {
      email: null
    }
  }

  canceledFilter: FilterOptions = {
    email: null
  }

  formState = {
    loading: false
  }

  state = {
    downloadLoading: false,
    isFilterOpen: false,
    isCanceledFilterOpen: false,
    loading: false,
    error: '',
    showEditForm: false,
    paymentLinks: {
      loading: false,
      links: []
    }
  }

  pagination: IPagination = {} as IPagination

  async mounted() {
    this.offer = await this.offerService.findByURL(this.$route.params.url)
    this.queue = await this.loadQueue(this.$route.params.url)
    this.form.valor_disponivel = this.queue.valor_disponivel
  }

  get investidores() {
    if (!this.queue.investidores) return []

    return this.queue.investidores
      .filter(i => {
        if (!this.filter.queue.email) return true

        return i.email.indexOf(this.filter.queue.email) >= 0
      })
      .sort((a, b) => a.posicao - b.posicao)
  }

  get awaitingPayment() {
    if (!this.queue.investidoresPagamentoGerado) return []

    return this.queue.investidoresPagamentoGerado
      .filter(i => {
        if (!this.filter.payment.email) return true

        return i.email.indexOf(this.filter.payment.email) >= 0
      })
      .sort((a, b) => a.posicao - b.posicao)
  }

  get canceledInvestors() {
    if (!this.queue.investidoresCancelados) return []

    return this.queue.investidoresCancelados
      .filter(i => {
        if (!this.filter.canceled.email) return true

        return i.email.indexOf(this.filter.canceled.email) >= 0
      })
  }

  setSelected(i) {
    const investidor = { ...i }
    this.selected = investidor
  }

  loadQueue(url_oferta: string): Promise<InvestmentQueue> {
    return this.investmentService.findQueueByOffer(url_oferta)
  }

  startDownload() {
    this.state.downloadLoading = true
  }

  finishDownload() {
    this.state.downloadLoading = false
  }

  async fetchData() {
    return this.queue.investidores
  }

  async fetchCanceledInvestors() {
    return this.queue.investidoresCancelados
  }

  async fetchAwaitingInvestors() {
    return this.queue.investidoresPagamentoGerado
  }

  onEditFormSubmit() {
    const form = { ...this.form, url_oferta: this.offer.url_oferta }

    this.formState.loading = true

    this.investmentService.editInvestmentQueue(form)
      .then(async() => {
        alert('Lista de espera atualizada com sucesso.')
        this.queue = await this.loadQueue(this.$route.params.url)
      })
      .catch(() => {
        alert('Erro ao atualizar lista de espera. Por favor contate o suporte.')
      })
      .then(() => {
        this.formState.loading = false
        this.state.showEditForm = false
        this.form.valor_disponivel = this.queue.valor_disponivel
      })
  }

  onPauseQueue() {
    const result = window.confirm('Tem certeza que deseja pausar a lista de espera?')

    if (!result) return

    this.formState.loading = true

    this.investmentService
      .pauseQueue(this.offer.url_oferta)
      .then((queue) => {
        alert('A lista de espera foi pausada.')
        this.queue = queue
      })
      .catch(() => {
        alert('Erro ao pausar lista de espera. Por favor contate o suporte.')
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  onResumeQueue() {
    const result = window.confirm('Tem certeza que deseja reativar a lista de espera?')

    if (!result) return

    this.formState.loading = true

    this.investmentService
      .resumeQueue(this.offer.url_oferta)
      .then((queue) => {
        alert('A lista de espera foi reativada.')
        this.queue = queue
      })
      .catch(() => {
        alert('Erro ao reativar lista de espera. Por favor contate o suporte.')
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  onCloseQueue() {
    const confirm = window.confirm('Tem certeza que deseja encerrar esta lista de espera?')

    if (!confirm) return

    this.formState.loading = true

    this.investmentService
      .closeQueue(this.offer.url_oferta)
      .then(() => {
        this.$router.push('/investimentos/lista-de-espera')
      })
      .catch(() => {
        alert('Erro ao encerrar a lista de espera. Contate o suporte.')
      })
      .then(() => { this.formState.loading = false })
  }

  removeFromQueue(i) {
    const result = window.confirm(`Tem certeza que deseja remover o investidor ${i.email} da lista de espera?`)

    if (!result) return

    this.formState.loading = true

    this.investmentService
      .removeFromQueue(this.offer.url_oferta, i.email)
      .then(async() => {
        alert('Investidor removido com sucesso.')
        this.queue = await this.loadQueue(this.$route.params.url)
      })
      .catch(() => {
        alert('Erro ao remover investidor da lista de espera. Por favor contate o suporte.')
      })
      .then(() => { this.formState.loading = false })
  }

  async onCreatePaymentLinkSubmit(selected) {
    const form: PaymentLinkForm = {} as PaymentLinkForm
    const investor = await this.userService.profile(selected.email)

    form.url_oferta = this.offer.url_oferta
    form.tipo = 'LISTA_ESPERA' as PaymentLinkType
    form.valor = selected.valor

    form.investidor = {
      nome: investor.nome_completo,
      email: investor.email,
      cpf_cnpj: investor.cpf,
      endereco: investor.endereco
    }

    const params = {
      nome: form.investidor.nome,
      email: form.investidor.email,
      valor: form.valor
    }

    this.formState.loading = true

    this.investmentQueueService
      .createPaymentLink(this.offer.url_oferta, params)
      .then(async() => {
        alert('O link de pagamento foi enviado para o email do investidor.');
        (this.$refs.closeModalBtn as any).click()

        this.queue = await this.loadQueue(this.$route.params.url)
      })
      .catch((request) => {
        try {
          const message = request.response.data.message
          alert(message)
        } catch (e) {
          console.log(e)
          alert('Erro ao criar link de pagamento. Por favor contate o suporte.')
        }
      })
      .then(() => { this.formState.loading = false })
  }

  getPaymentLinks(i) {
    this.state.paymentLinks.loading = true

    this.paymentLinkService.all({ email: i.email })
      .then(data => {
        this.state.paymentLinks.links = data.items as any
      })
      .then(() => {
        this.state.paymentLinks.loading = false
      })
  }

  cancelPaymentLink(i) {
    const confirm = window.confirm('Tem certeza que deseja cancelar o link de pagamento?')

    if (!confirm) return

    this.investmentQueueService
      .cancelPaymentLink(this.offer.url_oferta, i.id)
      .then((response) => {
        this.queue = response
        alert(`O link de pagamento de ${i.email} foi cancelado.`)
      })
      .catch((request) => {
        const message = request.response.data
        alert(message || 'Erro ao cancelar link de pagamento.')
      })
  }
}

