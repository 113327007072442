
























































































































































































































































































import { Investment, InvestmentToCSV, PaymentStatus } from '@/interfaces/investment'
import { PageOptions, Pagination as IPagination } from '@/lib/prest-query-builder'
import { OfferStatus } from '@/interfaces/offer'
import PrestPagination from '@/components/PrestPagination.vue'

import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: { PrestPagination }
})
export default class InvestmentsList extends Vue {
  @Prop({ required: false, default: '' }) urlOferta!: string
  InvestmentToCSV = InvestmentToCSV
  OfferStatus = OfferStatus
  PaymentStatus = PaymentStatus
  loading = false

  orderOptions = [
    { label: '+ Valor', value: 'valor' },
    { label: '- Valor', value: '-valor' }
  ]

  selectedOrder = '-valor'

  investments: Investment[] = []

  state = {
    downloadLoading: false,
    isFilterOpen: false,
    loading: false,
    error: ''
  }

  pageOptions: PageOptions = {
    page: 1,
    pageSize: 20
  }

  filters = {
    status: {
      type: '$eq',
      value: 'PAGO'
    },
    url_oferta: {
      type: '$eq',
      value: this.urlOferta
    },
    valor: {
      type: '$eq',
      value: null
    },
    email: {
      type: '$eq',
      value: null
    }
  }

  $http!: any
  $moment: any

  pagination: IPagination = {} as IPagination

  get prestIsReady() {
    return !this.$store.getters['prest/loading'] && !!this.$store.getters['prest/authorizationKey']
  }

  @Watch('prestIsReady', { immediate: true })
  onPrestIsReadyChange(value) {
    if (value) {
      this.fetchInvestments()
    }
  }

  fetchInvestments() {
    this.state.loading = true

    this.$store
      .dispatch('investment/search', {
        pagination: this.pageOptions,
        order: this.selectedOrder,
        filters: {
          ...this.filters
        }
      })
      .then((response) => {
        this.pagination = response.pagination
        this.investments = response.items
        this.state.loading = false
      })
  }

  async fetchInvestmentsByEmail(email) {
    this.state.loading = true

    const allInvestments = await this.$store
      .dispatch('investment/search', {
        pagination: false,
        filters: {
          status: {
            type: '$eq',
            value: 'PAGO'
          }
        }
      })

    const userInvestments = allInvestments
      .filter(i => {
        return i.investidor.email === email
      })
      .filter(i => {
        return this.urlOferta ? i.url_oferta === this.urlOferta : true
      })

    this.investments = userInvestments
    this.pagination = null as any
    this.state.loading = false
  }

  async onFilterSubmit() {
    if (this.filters.email) {
      this.fetchInvestmentsByEmail(this.filters.email.value)
    } else {
      this.fetchInvestments()
    }
  }

  async fetchData() {
    if (this.filters.email.value) {
      return this.investments
    } else {
      const investments = await this.$store
        .dispatch('investment/search', {
          pagination: false,
          order: this.selectedOrder,
          filters: {
            ...this.filters
          }
        })

      return investments
    }
  }

  startDownload() {
    this.state.downloadLoading = true
  }

  finishDownload() {
    this.state.downloadLoading = false
    this.$notify({
      type: 'success',
      title: 'Concluído',
      text: 'Download de arquivo concluído.',
      group: 'form'
    })
  }

  onToPage(n) {
    this.$set(this.pageOptions, 'page', n)
    this.fetchInvestments()
  }

  onFilterReset() {
    this.filters = {
      status: {
        type: '$eq',
        value: 'PAGO'
      },
      url_oferta: {
        type: '$eq',
        value: this.urlOferta
      },
      valor: {
        type: '$eq',
        value: null
      },
      email: {
        type: '$eq',
        value: null
      }
    }

    this.fetchInvestments()
  }

  @Watch('pageOptions.pageSize')
  onPageOptionsChange() {
    this.$set(this.pageOptions, 'page', 1)
    this.fetchInvestments()
  }

  @Watch('selectedOrder')
  onOrderChange() {
    this.$set(this.pageOptions, 'page', 1)
    this.fetchInvestments()
  }
}
