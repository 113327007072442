



















































import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import Component from 'vue-class-component'
import { groupBy } from 'underscore'
import { Investment } from '@/interfaces/pRest/investment'
import { InvestmentToCSV } from '@/interfaces/investment'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    apexchart: VueApexCharts
  }
})
export default class InvestmentsChart extends Vue {
  @Prop({ required: false }) title!: string
  investments: Investment[] = [] as any

  InvestmentToCSV = InvestmentToCSV

  $moment

  data: { x: string; y: number }[] = []

  series: any[] = [
    { data: this.data }
  ]

  state = {
    loading: false,
    downloadLoading: false
  }

  chartOptions = {
    chart: {
      id: 'investment-chart-line1',
      type: 'line',
      height: 230,
      toolbar: {
        autoSelected: 'pan',
        show: false
      }
    },
    colors: ['#546E7A', '#546E7A'],
    stroke: {
      width: 3,
      stroke: { curve: 'straight' }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return this.$options.filters?.kFormatter(value)
        }
      }
    }
  }

  seriesLine: any[] = [
    { data: this.data }
  ]

  chartOptionsLine = {
    chart: {
      id: 'investment-chart-line2',
      height: 130,
      type: 'area',
      brush: {
        target: 'investment-chart-line1',
        enabled: true
      },
      selection: {
        enabled: true,
        xaxis: {
          min: 0,
          max: 0
        }
      }
    },
    toolbar: {
      show: false,
      autoSelected: 'selection'
    },
    colors: ['#008FFB'],
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1
      }
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      tickAmount: 2,
      labels: {
        formatter: (value) => {
          return this.$options.filters?.kFormatter(value)
        }
      }
    }
  }

  get prestIsReady() {
    return !this.$store.getters['prest/loading'] && !!this.$store.getters['prest/authorizationKey']
  }

  @Watch('prestIsReady', { immediate: true })
  onPrestIsReadyChange(value) {
    if (value) {
      this.fetchInvestments()
    }
  }

  async fetchInvestments() {
    const investmentModel = this.$store.getters['prest/model']('investment')

    this.state.loading = true

    investmentModel
      // .join({
      //   type: 'inner',
      //   tableJoin: 'pagamento',
      //   tableJoinKey: 'id',
      //   operator: '$eq',
      //   table: 'investimento',
      //   tableKey: 'pagamento_id'
      // })
      .where({
        field: 'status',
        operator: '$eq',
        value: 'PAGO'
      })
      .where({
        field: 'criado',
        operator: '$gte',
        value: this.$moment().subtract(18, 'months').format('YYYY-MM-DD')
      })
      .order('criado')
      .run()
      .then((investments) => {
        this.investments = investments
        const data = this.transformData(investments)

        this.series = [{
          name: 'Investimentos',
          data: data
        }]

        this.seriesLine = [{
          name: 'Investimentos',
          data: data
        }]

        const { min, max } = this.getSelection(this.investments)

        this.chartOptionsLine = {
          ...this.chartOptionsLine,
          chart: {
            ...this.chartOptionsLine.chart,
            selection: {
              ...this.chartOptionsLine.chart.selection,
              xaxis: {
                min,
                max
              }
            }
          }
        }
      })
      .then(() => {
        this.state.loading = false
      })
  }

  getSelection(investments) {
    const format = 'YYYY MM DD'
    const lastDateStr = (investments[investments.length - 1] as any).data_pagamento

    const lastDate = this.$moment(lastDateStr).format(format)
    const firstDate = this.$moment(lastDate).subtract(1, 'months').format(format)

    return {
      min: new Date(firstDate).getTime(),
      max: new Date(lastDate).getTime()
    }
  }

  transformData(investments: Investment[]): { x: string; y: number }[] {
    const groupedByDay = groupBy(investments, (investment) => {
      const date = this.$moment(investment.criado).format('MM-DD-YYYY')
      return date
    })

    const finalFormat: { x: string; y: number }[] = Object.keys(groupedByDay).map(date => {
      const sum = groupedByDay[date].reduce((acc, cur) => {
        acc += cur.valor
        return acc
      }, 0)

      return {
        x: date,
        y: sum,
        size: groupedByDay[date].length
      }
    })

    return finalFormat
  }

  async fetchData() {
    const investments = await this.$store.dispatch('investment/search', { pagination: false })
    return investments
  }

  startDownload() {
    this.state.downloadLoading = true
  }

  finishDownload() {
    this.state.downloadLoading = false
    this.$notify({
      type: 'success',
      title: 'Concluído',
      text: 'Download de arquivo concluído.',
      group: 'form'
    })
  }
}

