




















































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Pagination } from '@/lib/prest-query-builder'

@Component({
  name: 'prest-pagination'
})
export default class PrestPagination extends Vue {
  @Prop({ required: true }) pagination!: Pagination

  get lastPages() {
    const end = this.pagination.totalPages
    const start = this.pagination.totalPages - 5
    const pages: number[] = []

    for (let p = start; p <= end; p++) {
      pages.push(p)
    }

    return pages
  }

  get hasSpan() {
    return this.pagination.totalPages > 15
  }

  get leftPages() {
    const span = 5
    const center = this.pagination.page

    let start = center - 5

    if (this.pagination.page < span) {
      start = 1
    }

    const distToEnd = this.pagination.totalPages - this.pagination.page

    const end = distToEnd > 5 ? center + 5 : center + distToEnd + 1

    const pages: number[] = []

    for (let p = start; p < end; p++) {
      pages.push(p)
    }

    return pages
  }
}
