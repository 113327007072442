import { Investment, InvestmentQueue } from '@/interfaces/investment'
import { Pagination } from '@/interfaces/pagination'
import axios, { AxiosInstance, AxiosResponse } from 'axios'

interface OpenInvestmentQueue {
  url_oferta: string;
  valor_disponivel: number;
}

export default class InvestmentService {
  private ENDPOINT = 'https://api.startmeup.com.br/Gateway/v1'
  private INVESTMENT_ENDPOINT = 'https://investimento-dot-startmeup-218419.appspot.com'
  private INVESTMENT_QUEUE_ENDPOINT = 'https://lista-espera-dot-startmeup-218419.rj.r.appspot.com'

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  findByPublicKey(publicKey: string) {
    return this.client
      .get(`${this.ENDPOINT}/investimento/busca/${publicKey}`)
      .then((response: AxiosResponse) => response.data.filter((i) => !!i.oferta))
      .catch((response) => response)
  }

  investmentsByEmail(email: string) {
    return this.client
      .get(`${this.INVESTMENT_ENDPOINT}/investimento/investidor/lista`, { params: { email } })
      .then(response => response.data)
  }

  allQueues(): Promise<InvestmentQueue[]> {
    return this.client
      .get(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/lista`)
      .then(response => response.data)
  }

  openInvestmentQueue(form: OpenInvestmentQueue) {
    return this.client
      .post(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/nova`, { ...form })
      .then(response => response.data)
  }

  editInvestmentQueue(form: { url_oferta: string; valor_disponivel: number }) {
    return this.client
      .put(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/atualiza/${form.url_oferta}`, { ...form })
      .then(response => response.data)
  }

  pauseQueue(url_oferta: string) {
    return this.client
      .post(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/inativa/${url_oferta}`)
      .then((response) => response.data)
  }

  resumeQueue(url_oferta: string) {
    return this.client
      .post(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/ativa/${url_oferta}`)
      .then((response) => response.data)
  }

  closeQueue(url_oferta: string) {
    return this.client
      .post(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/fecha/${url_oferta}`)
      .then((response) => response.data)
  }

  removeFromQueue(url_oferta: string, email: string) {
    return this.client
      .put(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/investidor/cancela/${url_oferta}`, {}, { params: { email } })
      .then((response) => response.data)
  }

  findQueueByOffer(url_oferta: string) {
    return this.client
      .get(`${this.INVESTMENT_QUEUE_ENDPOINT}/lista-de-espera/busca`, { params: { oferta: url_oferta } })
      .then((response) => response.data)
  }

  investments(options): Promise<{ items: Investment[]; pagination: Pagination }> {
    return this.client
      .get(`${this.INVESTMENT_ENDPOINT}/investimento/paginacao/busca`, { params: this.sanitizeQueryParams(options) })
      .then((response: AxiosResponse) => response.data)
      .then((data) => {
        return {
          items: data.items,
          pagination: {
            itemsPorPagina: data.itemsPorPagina,
            numeroDePaginas: data.numeroDePaginas,
            pagina: data.pagina,
            proximaPagina: data.proximaPagina,
            temPaginaAnterior: data.temPaginaAnterior,
            temProximaPagina: data.temProximaPagina
          }
        }
      })
      .catch(() => {
        return {
          items: [],
          pagination: {
            itemsPorPagina: 10,
            numeroDePaginas: 0,
            pagina: 0,
            proximaPagina: 0,
            temPaginaAnterior: false,
            temProximaPagina: false
          }
        }
      })
  }

  allTimeInvestments() {
    return this.client
      .get(`${this.INVESTMENT_ENDPOINT}/investimento/lista`)
      .then(response => response.data)
  }

  // Remove null, undefined and empty strings from object.
  private sanitizeQueryParams(object: Record<string, any>) {
    return Object
      .keys(object)
      .filter(key => typeof object[key] === 'boolean' || object[key] === 0 || !!object[key])
      .reduce((acc, cur) => { acc[cur] = object[cur]; return acc }, {})
  }
}
