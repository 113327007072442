







import Vue from 'vue'
import Component from 'vue-class-component'
import InvestmentsList from '@/components/lists/InvestmentsList.vue'
import InvestmentsChart from '@/components/charts/InvestmentsChart.vue'

@Component({
  components: { InvestmentsList, InvestmentsChart }
})
export default class AllInvestments extends Vue {

}
