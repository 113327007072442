















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import * as lang from 'vuejs-datepicker/src/locale'

import { ChartType } from 'chart.js/dist/Chart.js'
import * as Chart from 'chart.js/dist/Chart.js'

import { Investment } from '@/interfaces/pRest/investment'

import { groupBy } from 'underscore'

import { mapGetters } from 'vuex'

const ChartJS = (window as any).Chart

const PaymentsByMonthResumeCSV = {
  // eslint-disable-next-line
  'Status': 'label',
  'Qtd de faturas': 'total',
  'Total em R$': 'sum',
  'Data Inicio': 'start',
  'Data Fim': 'end'
}

@Component({
  computed: {
    ...mapGetters({
      loading: 'investment/loading'
    })
  }
})
export default class InvestmentsByPaymentStatusByMonth extends Vue {
  @Prop({ required: false, default: '' }) urlOferta!: string
  @Prop({ required: false, default: 'Montante x Status de Pagamento' }) title!: string
  @Prop({ required: false, default: true }) enableFilters!: boolean

  investments: Investment[] = []

  loading!: boolean
  downloadLoading = false

  languages = lang

  PaymentsByMonthResumeCSV = PaymentsByMonthResumeCSV

  paymentStatusChart!: ChartType

  labels: string[] = []

  datasets: Chart.ChartDataSets[] = []

  data: any[] = []

  form: { query: null | Date } = {
    query: null
  }

  $moment

  colors = {
    PAGO: '#a6d854',
    EXPIRADO: '#e78ac3',
    PENDENTE: '#8da0cb',
    CANCELADO: '#fc8d62',
    REEMBOLSADO: '#66c2a5'
  }

  formState = {
    loading: false,
    downloadLoading: false
  }

  @Watch('investments')
  onInvestmentsChange(cur: Investment[]) {
    if (!cur.length) return

    this.data = this.transformData(cur, this.form.query as Date)

    const labels = this.data.map(d => d.label)
    const dataset = this.data.map(d => d.sum)
    const colors = this.data.map(d => this.colors[d.label])

    this.renderChart(this.paymentStatusChart, labels, [{
      data: dataset,
      backgroundColor: colors
    }])
  }

  mounted() {
    if (this.enableFilters) {
      this.form.query = this.$moment().startOf('month').toDate()
    }

    this.fetchInvestments()
    this.initPaymentStatusChart()
  }

  fetchInvestments() {
    if (this.urlOferta) {
      this.$store.dispatch('investment/search', {
        pagination: false,
        filters: {
          status: {
            type: '$eq',
            value: 'PAGO'
          },
          url_oferta: {
            type: '$eq',
            value: this.urlOferta
          }
        }
      })
        .then(investments => {
          this.investments = investments
        })
    } else {
      this.$store
        .dispatch('investment/search', {
          pagination: false
        })
        .then(investments => {
          this.investments = investments
        })
    }
  }

  renderChart(chart, labels, datasets) {
    chart.data.labels = labels
    chart.data.datasets = datasets

    chart.update()
  }

  initPaymentStatusChart() {
    const ctx = (this.$refs.paymentStatusChart as any).getContext('2d')

    this.paymentStatusChart = new ChartJS(ctx, {
      type: 'horizontalBar',
      barThickness: 1,
      data: {
        labels: this.labels,
        datasets: [{
          barPercentage: 0.1,
          barThickness: 1,
          maxBarThickness: 10,
          label: false,
          data: this.datasets,
          backgroundColor: [
            '#B4DC7F',
            '#78A1BB',
            '#EDAE49',
            '#FFA0AC'
          ],
          borderColor: [
            '#317B22',
            '#5284A3',
            '#BA7A12',
            '#FF5C72'
          ],
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              mirror: true,
              callback: (value) => {
                return this.$options.filters?.kFormatter(value)
              }
            }
          }],
          yAxes: [{
            maxBarThickness: 100
          }]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$options.filters?.currency(tooltipItem.xLabel)
            }
          }
        },
        maintainAspectRatio: false,
        responsive: true
      }
    })
  }

  transformData(investments: Investment[], date: Date) {
    let filtered: Investment[] = [...investments]

    if (date) {
      const selectedMonthAndYear = this.$moment(date).format('MM/YYYY')

      // Pega os investimentos do mês selecionado
      filtered = investments.filter((i) => {
        return this.$moment(i.criado).format('MM/YYYY') === selectedMonthAndYear
      })
    }

    const groupedByStatus = groupBy(filtered, (i: Investment) => i && i.status)

    const data = Object.keys(groupedByStatus).map((status) => {
      const sum = groupedByStatus[status].reduce((acc, cur) => {
        acc += cur.valor
        return acc
      }, 0)

      const total = groupedByStatus[status].length

      const start = this.$moment(date)
      const end = this.$moment(date).endOf('month')

      return {
        label: status,
        total,
        sum,
        start: start.format('DD/MM/YYYY HH:mm:ss'),
        end: end.format('DD/MM/YYYY HH:mm:ss')
      }
    })

    return data
  }

  onFormSubmit() {
    this.formState.loading = true

    // Esse timeout é para dar tempo do innercontent bloquear a tela
    setTimeout(() => {
      this.data = this.transformData(this.investments, this.form.query as Date)

      const labels = this.data.map(d => d.label)
      const dataset = this.data.map(d => d.sum)
      const colors = this.data.map(d => this.colors[d.label])

      this.renderChart(this.paymentStatusChart, labels, [{
        data: dataset,
        backgroundColor: colors
      }])

      this.formState.loading = false
    }, 100)
  }

  async fetchData() {
    return await new Promise((resolve) => {
      setTimeout(() => {
        const d = [...this.data]
        resolve(d)
      }, 100)
    })
  }

  startDownload() {
    this.downloadLoading = true
  }

  finishDownload() {
    this.downloadLoading = false
  }
}
