


































































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import { Offer, ContractType, OfferType, OfferStatus } from '@/interfaces/offer'
import 'chart.js/dist/Chart.js'
import InvestmentsByPaymentStatusByMonth from '@/components/charts/InvestmentsByPaymentStatusByMonth.vue'
import TopInvestors from '@/components/charts/TopInvestors.vue'
import InvestmentsList from '@/components/lists/InvestmentsList.vue'

@Component({
  components: { InvestmentsByPaymentStatusByMonth, TopInvestors, InvestmentsList }
})
export default class Users extends Vue {
  OfferType = OfferType
  ContractType = ContractType
  OfferStatus = OfferStatus

  private offerService = new OfferService();

  offer: Offer = {} as Offer

  async mounted() {
    this.offer = await this.offerService.findByURL(this.$route.params.url)
  }
}

